<template>
  <div>
    <v-row justify="center">
      <div row class="mb-4 custom-radio-group">
        <v-col cols="4">
          <div
            class="custom-radio-card"
            :class="{ selected: localRecordingType === 'camera' }"
            @click="localRecordingType = 'camera'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon"
            >
              <path d="M23 7l-7 5 7 5V7z" />
              <rect x="1" y="5" width="15" height="14" rx="2" ry="2" />
            </svg>
            <span>Camera</span>
            <v-icon v-if="localRecordingType === 'camera'" class="check-icon"
              >mdi-check-circle</v-icon
            >
          </div>
        </v-col>

        <v-col cols="4">
          <div
            class="custom-radio-card"
            :class="{ selected: localRecordingType === 'screen' }"
            @click="localRecordingType = 'screen'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon"
            >
              <rect x="2" y="3" width="20" height="14" rx="2" ry="2" />
              <path d="M8 21h8m-4-4v4" />
            </svg>
            <span>Screen</span>
            <v-icon v-if="localRecordingType === 'screen'" class="check-icon"
              >mdi-check-circle</v-icon
            >
          </div>
        </v-col>

        <v-col cols="4">
          <div
            class="custom-radio-card"
            :class="{ selected: localRecordingType === 'screenCam' }"
            @click="localRecordingType = 'screenCam'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon"
            >
              <!-- Screen Frame -->
              <rect x="2" y="3" width="20" height="14" rx="2" ry="2" />

              <!-- Stand for the Screen -->
              <path d="M8 21h8m-4-4v4" />

              <!-- Camera Icon (Lens) -->
              <circle cx="16" cy="8" r="1" />

              <!-- Camera Body -->
              <rect x="11" y="7" width="7" height="5" rx="1" />
            </svg>

            <span>Screen + Cam</span>
            <v-icon v-if="localRecordingType === 'screenCam'" class="check-icon"
              >mdi-check-circle</v-icon
            >
          </div>
        </v-col>
      </div>
    </v-row>

    <v-row justify="center">
      <div class="custom-box">
        <label for="" class="label">Select video resolution</label>
        <v-col class="d-flex left-aligned-menu" cols="12" sm="12">
          <v-select
            v-model="videoQuality"
            background-color="secondary"
            :items="videoQualityOptions"
            item-text="text"
            item-value="value"
            class="custom-select-box"
            style="width: 84%; margin: 0 auto"
            dense
            outlined
            eager
          ></v-select>
        </v-col>
      </div>
    </v-row>

    <v-row justify="center" class="my-0">
      <v-btn
        text
        class="secondary text-capitalize mb-4 px-2 custom-mic"
        @click="microphoneEnabled = !microphoneEnabled"
      >
        <v-icon size="17px" color="black" class="mr-1"> mdi-microphone </v-icon>
        <small class="mic-chip">
          Default - Microphone
          <v-chip label x-small :class="microphoneSwitchClasses">
            {{ microphoneEnabled ? "on" : "off" }}
          </v-chip>
        </small>
      </v-btn>
    </v-row>

    <v-btn
      large
      :color="buttonColor"
      :style="'color: ' + buttonTextColor"
      @click="startRecording"
      class="mb-4 rounded-xl capitalize px-5 custom-start-btn"
    >
      <template v-if="isLoading">
        <v-progress-circular
          indeterminate
          size="20"
          color="white"
          class="mr-2"
        ></v-progress-circular>
        Starting...
      </template>
      <template v-else> Start Recording </template>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "RecordingOptions",
  props: {
    recordingType: {
      type: String,
      default: "screen",
    },
    buttonColor: {
      type: String,
      default: "primary",
    },
    buttonTextColor: {
      type: String,
      default: "#FFFFFF",
    },
  },
  data() {
    return {
      localRecordingType: this.recordingType,
      videoQuality: 8000000,
      microphoneEnabled: true,
      isLoading: false,
      videoQualityOptions: [
        { text: "SD (Standard Definition) 360p", value: 1000000 },
        { text: "FSD (Full Standard Definition) 480p", value: 2500000 },
        { text: "HD (High Definition) 720p", value: 5000000 },
        { text: "FHD (Full High Definition) 1080p", value: 8000000 },
        { text: "2k Video (Quad HD)", value: 16000000 },
        { text: "4k Video (UHD)", value: 40000000 },
      ],
    };
  },
  watch: {
    localRecordingType(newVal) {
      this.updateRecordingType(newVal);
    },
    videoQuality(newVal) {
      this.updateVideoQuality(newVal);
    },
    microphoneEnabled(newVal) {
      this.updateMicrophoneEnabled(newVal);
    },
  },
  computed: {
    microphoneSwitchClasses() {
      return {
        primary: this.microphoneEnabled,
        red: !this.microphoneEnabled,
        "px-1": true,
        "switch-on-color": this.microphoneEnabled,
        "switch-off-color": !this.microphoneEnabled,
      };
    },
  },
  methods: {
    startRecording() {
      this.isLoading = true;
      this.$emit("start-recording", {
        recordingType: this.localRecordingType,
        videoQuality: this.videoQuality,
        microphoneEnabled: this.microphoneEnabled,
      });
    },
    updateRecordingType(newVal) {
      this.$emit("update:recordingType", newVal);
    },
    updateVideoQuality(newVal) {
      this.$emit("update:videoQuality", newVal);
    },
    updateMicrophoneEnabled(newVal) {
      this.$emit("update:microphoneEnabled", newVal);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-start-btn {
  width: 270px !important;
  height: 50px !important;
  border-radius: 50px !important;
  font-size: 15px !important;
}
.custom-mic {
  border: 1px solid #eee !important;
  width: 250px !important;
  height: 40px !important;
}
.switch-off-color {
  color: #fff !important;
}
.switch-on-color {
  color: #fff !important;
}
.mic-chip {
  color: #000;
}
.label {
  font-size: 11px !important;
  color: grey !important;
}
.custom-select-box {
  font-size: 11px !important;
}
.custom-box {
  text-align: left !important;
  width: 250px !important;
}
.left-aligned-menu {
  text-align: left !important;
  padding: 0px;
}
.custom-radio-group {
  display: flex;
  justify-content: center;
  gap: 2px;
}

.custom-radio-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 80px;
  border: 2px solid #ccc;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
}

.custom-radio-card .icon {
  width: 28px;
  height: 28px;
  color: #888;
}

.custom-radio-card span {
  margin-top: 4px;
  font-weight: 500;
  color: #666;
  font-size: 12px;
}

.custom-radio-card.selected {
  border-color: #3f51b5;
  background-color: rgba(63, 81, 181, 0.1);
}

.custom-radio-card.selected .icon,
.custom-radio-card.selected span {
  color: #3f51b5;
}

.check-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #3f51b5;
}
</style>
