<template>
  <div>
    <div class="d-flex justify-center">
      <strong>Thanks, Uploaded successfully!</strong>
    </div>
    <div class="d-flex justify-center mt-5">
      <v-icon color="success" x-large>mdi-check-circle</v-icon>
    </div>
    <div class="d-flex justify-center mt-8">
      <v-btn @click="copyLink" class="capitalize mr-7" rounded color="primary">
        <v-icon>mdi-link-variant</v-icon>
        Copy Link
      </v-btn>
      <v-btn
        v-if="!isRequestLink"
        @click="backToDashbord"
        class="capitalize mr-7"
        rounded
      >
        <v-icon>mdi-arrow-left</v-icon>
        Dashboard
      </v-btn>
      <v-btn @click="redoRecording" class="capitalize" rounded>
        <v-icon>mdi-restart</v-icon>
        Record again
      </v-btn>
    </div>
    <v-snackbar
      :color="snackBarColor"
      :outlined="isOutlined"
      v-model="snackbar"
    >
      {{ snackBarText }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "UploadCompleted",
  props: {
    isRequestLink: Boolean,
  },
  data() {
    return {
      snackbar: false,
      snackBarText: "",
      snackBarColor: "",
      isOutlined: false,
    };
  },
  methods: {
    redoRecording() {
      this.$emit("redo-recording");
    },
    async copyLink() {
      const response = await window.axios.get(
        "/recordings/copy-link-of-last-recording"
      );
      const link = this.optimizedRecordingLink(
        response.data.id,
        response.data.domain
      );
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.snackBarText = "Recording link copied";
          this.snackbar = !this.snackbar;
          this.snackBarColor = "primary";
          this.isOutlined = true;
          console.log("Link copied to clipboard!");
        })
        .catch((error) => {
          this.snackBarText = "Failed to copy link to clipboard, try again!";
          this.snackbar = !this.snackbar;
          this.snackBarColor = "error";
          console.error("Failed to copy link to clipboard", error);
        });
    },
    optimizedRecordingLink(Id, domain) {
      let link = "";
      let appStage = process.env.NODE_ENV;
      let protocol = appStage === "development" ? "http://" : "https://";
      link = protocol + domain + "/share/" + Id;
      return link;
    },
    backToDashbord() {
      const appUrl = process.env.VUE_APP_URL;
      window.location.href = `${appUrl}/dashboard`;
    },
  },
};
</script>

<style scoped></style>
