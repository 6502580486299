<template>
  <div>
    <v-toolbar
      class="font-weight-bold text-h5 mt-n5 d-flex justify-center capitalize"
      flat
      color="transparent"
    >
      Final Step, upload your recording
    </v-toolbar>
    <v-form lazy-validation ref="form" class="text-left">
      <div v-if="recordingMode === 'accountRecording'">
        <label>
          Folder
          <small>(Optional)</small>
        </label>
        <v-select
          :items="folders_item"
          v-model="form.record_folder"
          class="v-folder-items"
          item-text="name"
          item-value="id"
          background-color="secondary"
          placeholder="Choose a folder"
          dense
          solo
          flat
        ></v-select>
      </div>
      <div v-if="recordingMode === 'linkRecording'">
        <label>Your Name <span class="error--text">*</span></label>
        <v-text-field
          solo
          dense
          flat
          background-color="secondary"
          placeholder="Enter your name"
          v-model="form.name"
          :rules="validate.required('Name')"
          type="text"
        ></v-text-field>
        <label>Email Address <span class="error--text">*</span></label>
        <v-text-field
          solo
          dense
          flat
          background-color="secondary"
          placeholder="Enter your Email"
          v-model="form.email"
          :rules="validate.required('Email Address')"
          type="email"
        ></v-text-field>
      </div>
      <label>
        Recording Title
        <small>(This can be changed later)</small>
      </label>
      <v-text-field
        solo
        dense
        flat
        background-color="secondary"
        placeholder="Enter the recording title"
        v-model="form.record_title"
        type="email"
      ></v-text-field>
      <label>Message <small>(This can be changed later)</small></label>
      <v-textarea
        solo
        dense
        flat
        background-color="secondary"
        type="text"
        v-model="form.record_message"
        placeholder="Type your message"
      ></v-textarea>
    </v-form>
    <v-card-actions class="d-flex justify-center">
      <v-btn
        @click="goBack"
        class="secondary black--text px-6 mr-4 capitalize custom-back-btn"
        rounded
        large
      >
        <v-icon>mdi-keyboard-backspace</v-icon>
        Go back
      </v-btn>
      <v-btn
        :color="buttonColor"
        :style="'color: ' + buttonTextColor"
        class="capitalize px-8 white--text"
        rounded
        large
        :loading="loading"
        @click="$refs.form.validate() ? uploadVideo() : null"
      >
        Upload Video
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import FormValidation from "../../utils/FormValidation";
import ResponseHelper from "../../utils/ResponseHelper";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FinalStep",
  props: {
    recordingMode: String,
    buttonColor: {
      type: String,
      default: "primary",
    },
    buttonTextColor: {
      type: String,
      default: "#FFFFFF",
    },
  },
  data() {
    return {
      form: {
        record_folder: null,
      },
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      loading: false,
      folders_item: [],
    };
  },
  created() {
    // Need to make adjustment in here
    if (this.$route.path.includes("/rec")) {
      this.form.name = this.user.name ?? null;
      this.form.email = this.user.email ?? null;
      this.form.user_id = this.user["account_id"] ?? null;
    }
  },
  mounted() {
    this.myFoldersItem();
  },
  methods: {
    ...mapActions("recordings", ["user_can_upload"]),
    ...mapActions("folders", ["user_folders_list"]),
    goBack() {
      this.$emit("go-back");
    },
    async myFoldersItem() {
      if (this.form.user_id !== null && !this.requestedLink) {
        const userId = this.form.user_id;
        try {
          const response = await this.user_folders_list(userId);
          this.folders_item = response.data;
        } catch (e) {
          this.toast.sendError(e);
        }
      }
    },
    async uploadVideo() {
      this.$emit("upload-video", this.form);
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      default_domain: "domain/default_domain",
    }),
    ...mapGetters("recordings", ["user_details"]),
    // This will be change or REMOVE later!
    requestedLink() {
      if (this.$route.path.includes("/request")) {
        return { requested_link: "Yes" };
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-folder-items {
  text-align: left !important;
}
.custom-back-btn {
  width: 120px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
  border: 1px solid #ddd !important;
}
</style>
