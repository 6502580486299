<template>
  <div>
    <div class="video-container">
      <video
        ref="previewVideo"
        :src="recordedVideoUrl"
        controls
        download
        playsinline
        class="plyr"
      ></video>
    </div>
    <div class="bottom-container">
      <v-btn
        @click="redoRecording"
        class="secondary black--text capitalize custom-redo-btn"
        rounded
        large
      >
        <v-icon>mdi-reload</v-icon>
        Redo
      </v-btn>
      <v-btn
        @click="proceedToFinal"
        :color="buttonColor"
        :style="'color: ' + buttonTextColor"
        class="white--text capitalize ml-6 custom-final-step-btn"
        rounded
        large
      >
        Proceed
      </v-btn>
    </div>
  </div>
</template>

<script>
import Plyr from "plyr";
import "plyr/dist/plyr.css";
export default {
  name: "Preview",
  props: {
    recordedVideoUrl: String,
    buttonColor: {
      type: String,
      default: "primary",
    },
    buttonTextColor: {
      type: String,
      default: "#FFFFFF",
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.initPlyr();
  },
  watch: {
    recordedVideoUrl(newUrl) {
      if (newUrl) {
        this.initPlyr();
      }
    },
  },
  methods: {
    initPlyr() {
      this.$nextTick(() => {
        if (this.player) {
          this.player.destroy();
        }
        this.player = new Plyr(this.$refs.previewVideo, {
          controls: [
            "play-large",
            "restart",
            "play",
            "progress",
            "current-time",
            "duration",
            "mute",
            "volume",
            "captions",
            "settings",
            "airplay",
            "pip",
            "fullscreen",
            "download",
          ],
          settings: ["captions", "speed", "quality"],
          autoplay: false,
          keyboard: { focused: true, global: true },
          fullscreen: { enabled: true, fallback: true, iosNative: true },
        });
      });
    },
    redoRecording() {
      this.$emit("redo-record");
    },
    proceedToFinal() {
      this.$emit("proceed-to-final");
    },
  },
  beforeUnmount() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>

<style lang="scss" scoped>
.plyr {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.video-container {
  width: 100%;
  max-width: 1200px;
  min-height: 364px auto !important;
  max-height: 675px auto !important;
  margin: 0 auto 40px;
  padding: 1px !important;
  position: relative;
  background-color: #000;
  border: 2px solid rgba(0, 0, 0, 0.8);
  border-radius: 12px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease;
  // aspect-ratio: 16 / 9;

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #000;
  }
}
.bottom-container {
  margin-top: 35px !important;
}
.custom-final-step-btn {
  padding: 6px 25px !important;
  border-radius: 50px !important;
}
.custom-redo-btn {
  width: 120px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
  border: 1px solid #ddd !important;
}
@media (max-width: 1200px) {
  .video-container {
    max-height: none;
    height: 56.25vw; // Maintains 16:9 ratio on smaller screens
  }
}

@media (max-width: 768px) {
  .video-container {
    margin-bottom: 50px;
  }
}
</style>
