<template>
  <div>
    <div class="controls-container">
      <div class="recording-timer">
        <template v-if="!isPaused">
          <img src="@/assets/icons/progress.gif" class="timer-icon" alt="" />
        </template>
        <template v-else>
          <v-icon class="timer-icon" color="red">mdi-pause</v-icon>
        </template>
        {{ formattedRecordingTime }}
      </div>
      <div>
        <v-btn
          @click="togglePause"
          :color="isPaused ? 'error' : 'default'"
          class="btn"
        >
          <v-icon left>{{ isPaused ? "mdi-play" : "mdi-pause" }}</v-icon>
          {{ isPaused ? "Resume" : "Pause" }}
        </v-btn>
        <v-btn
          @click="stopRecording"
          :color="isPaused ? 'light-red' : 'error'"
          class="btn ml-3"
        >
          <template v-if="isLoading">
            <v-progress-circular
              indeterminate
              size="20"
              color="white"
              class="mr-2"
            ></v-progress-circular>
            Stopping...
          </template>
          <template v-else>
            <v-icon left :color="isPaused ? 'red' : 'white'">mdi-stop</v-icon>
            Stop
          </template>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecordingControls",
  props: {
    isPaused: Boolean,
    formattedRecordingTime: String,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    togglePause() {
      this.$emit("toggle-pause");
    },
    stopRecording() {
      this.isLoading = true;
      this.$emit("stop-recording");
    },
  },
};
</script>

<style lang="scss" scoped>
.recording-timer {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  display: flex;
  align-items: center;
  .timer-icon {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 10px 0px;
  .btn {
    border: 1px solid #333;
    text-transform: capitalize !important;
  }
}
</style>
