<template>
  <v-container
    fluid
    fill-height
    class="recording-container"
    :style="'background: ' + brandColor"
  >
    <v-overlay v-model="loadScreen">
      <v-progress-circular size="40"></v-progress-circular>
    </v-overlay>
    <v-card class="mx-auto rounded-xl pa-4 mt-5 custom-card" width="700">
      <v-card-title class="justify-center">
        <div class="center-logo">
          <router-link to="/">
            <v-progress-circular
              v-if="loadScreen"
              :width="3"
              indeterminate
            ></v-progress-circular>
            <v-img
              v-else
              :src="appDomainLogoUrl"
              class="logo-size"
              alt="Recording App Logo"
              contain
            ></v-img>
          </router-link>
        </div>
      </v-card-title>
      <v-card-text>
        <v-toolbar
          v-if="isFirstPage && !isRequestLink"
          class="font-weight-bold text-h5 mb-5 mt-n5 d-flex justify-center"
          flat
          color="transparent"
          >Start Your Recording</v-toolbar
        >
        <v-toolbar
          v-if="isFirstPage && isRequestLink"
          class="font-weight-bold text-h6 mb-5 mt-n5 d-flex justify-center"
          flat
          color="transparent"
          >{{ receiver_name_email }} has requested you to record a
          video</v-toolbar
        >

        <RecordingOptions
          v-if="isFirstPage"
          :recording-type="recordingType"
          :buttonColor="buttonColor"
          :buttonTextColor="buttonTextColor"
          @start-recording="startRecordingAndEnterPiP"
          @update:recordingType="updateRecordingType"
          @update:videoQuality="updateVideoQuality"
          @update:microphoneEnabled="updateMicrophoneEnabled"
        />

        <Preview
          v-if="isPreviewMode"
          :buttonColor="buttonColor"
          :buttonTextColor="buttonTextColor"
          :recorded-video-url="recordedVideoUrl"
          @redo-record="redoRecording"
          @proceed-to-final="proceedToFinal"
        />

        <div
          v-if="isRecording && recordingType === 'camera'"
          class="content-preview"
        >
          <video ref="cameraPreview" autoplay muted></video>
        </div>

        <div v-if="isScreenRecording" class="content-preview">
          <video ref="screenPreview" autoplay muted></video>
          <div class="overlay">
            <img
              src="@/assets/icons/checked.png"
              alt="Check Icon"
              class="overlay-icon"
            />
            <div class="overlay-text">
              <h2>All good!</h2>
              <p>Your screen is being shared.</p>
            </div>
          </div>
        </div>

        <RecordingControls
          v-if="isRecording"
          :is-paused="isPaused"
          :formatted-recording-time="formattedRecordingTime"
          @toggle-pause="togglePause"
          @stop-recording="stopRecording"
        />

        <!-- The `recordingMode` can be either of "accountRecording" or "linkRecording" -->
        <FinalStep
          v-if="isFinalStep"
          :buttonColor="buttonColor"
          :buttonTextColor="buttonTextColor"
          :recordingMode="isRequestLink ? 'linkRecording' : 'accountRecording'"
          @go-back="goBack"
          @upload-video="uploadVideo"
        />

        <UploadProgress
          v-if="isUploading"
          :progress-tracker="progressTracker"
          @cancel-upload="cancelUpload"
        />

        <UploadCompleted
          v-if="isCompleted"
          :is-request-link="isRequestLink"
          @redo-recording="redoRecording"
        />

        <div v-if="isFirstPage" class="mt-10">
          <strong class="capitalize error--text">protect your privacy</strong>
          <small class="d-block" style="color: #000000">
            Before you start recording, you'll be prompted which screen or
            window you would like to record. You can preview your recording on
            this page before you decide to upload it.
          </small>
        </div>
      </v-card-text>
    </v-card>

    <CountdownOverlay
      :is-counting-down="isCountingDown"
      :countdown-value="countdownValue"
    />
  </v-container>
</template>

<script>
/* global EBML */
import { mapActions, mapGetters } from "vuex";
import RecordRTC from "recordrtc";
import Resumable from "resumablejs";
import ResponseHelper from "../../utils/ResponseHelper";
import RecordingOptions from "../../components/Rec/RecordingOptions.vue";
import RecordingControls from "../../components/Rec/RecordingControls.vue";
import Preview from "../../components/Rec/Preview.vue";
import FinalStep from "../../components/Rec/FinalStep.vue";
import UploadProgress from "../../components/Rec/UploadProgress.vue";
import UploadCompleted from "../../components/Rec/UploadCompleted.vue";
import CountdownOverlay from "../../components/Rec/CountdownOverlay.vue";

export default {
  components: {
    RecordingOptions,
    RecordingControls,
    Preview,
    FinalStep,
    UploadProgress,
    UploadCompleted,
    CountdownOverlay,
  },
  name: "RecordingView",
  data() {
    return {
      toast: new ResponseHelper(),
      recordingType: "screen",
      videoQuality: 8000000,
      microphoneEnabled: true,
      isRecording: false,
      isPreviewMode: false,
      isCountingDown: false,
      isFirstPage: true,
      isUploading: false,
      isFinalStep: false,
      isCompleted: false,
      countdownValue: 3,
      recorder: null,
      recordedVideo: null,
      recordedVideoUrl: null,
      cameraStream: null,
      screenStream: null,
      audioStream: null,
      combinedStream: null,
      isPaused: false,
      pipVideo: null,
      miniPreview: null,
      miniPreviewVideo: null,
      miniCameraStream: null,
      isDragging: false,
      dragOffset: { x: 0, y: 0 },
      isPiPSupported: false,
      recordingStartTime: null,
      recordingTimer: null,
      totalRecordingTime: 0,
      pausedTime: 0,
      lastPauseTime: null,
      progressTracker: null,
      uploadToken: null,
      isGeneratingToken: false,
      form: {},
      video_name: "",
      loading: false,
      resumable: null,
      receiver_name_email: null,
      loadScreen: false,
      appDomainLogoUrl: null,
      brandColor: "#E9F0FA",
      buttonColor: "primary",
      buttonTextColor: "#FFFFFF",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formattedRecordingTime() {
      const totalSeconds = Math.floor(this.totalRecordingTime / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
    getVideoQualityValue() {
      let quality = 8000000;
      const resolution = this.videoQuality;
      switch (resolution) {
        case 1000000:
          quality = "360p";
          break;
        case 2500000:
          quality = "480p";
          break;
        case 5000000:
          quality = "720p";
          break;
        case 8000000:
          quality = "1080p";
          break;
        case 16000000:
          quality = "2k";
          break;
        case 40000000:
          quality = "4k";
          break;
      }
      return quality;
    },
    isScreenRecording() {
      return (
        this.isRecording &&
        (this.recordingType === "screen" || this.recordingType === "screenCam")
      );
    },
    isRequestLink() {
      return !!this.$route.params.personal_url;
    },
  },
  created() {
    if (/\/rec(ording)?/.test(this.$route.path)) {
      this.form.name = this.user.name ?? null;
      this.form.email = this.user.email ?? null;
      this.form.user_id = this.user["account_id"] ?? null;
      console.log("URL is rec or record");
    }
    if (this.isRequestLink) {
      this.fetchRequestUserBranding();
    }
    if (!this.isRequestLink) {
      this.fetchAuthUserBranding();
    }
  },
  mounted() {
    // Check PiP support - explicitly checking for Firefox
    this.isPiPSupported =
      document.pictureInPictureEnabled &&
      !navigator.userAgent.toLowerCase().includes("firefox");

    // Add beforeunload event listener
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  methods: {
    ...mapActions("recordings", [
      "get_req_user_branding",
      "get_user_branding",
      "user_can_upload",
    ]),
    updateRecordingType(newType) {
      this.recordingType = newType;
    },
    updateVideoQuality(newType) {
      this.videoQuality = newType;
    },
    updateMicrophoneEnabled(newType) {
      this.microphoneEnabled = newType;
    },
    async startRecordingAndEnterPiP() {
      try {
        this.isRecording = true;
        this.isFirstPage = false;

        // Only attempt PiP if the recording type is "screenCam"
        if (this.recordingType === "screenCam") {
          await this.attemptEnterPiP();
        }

        // Setup preview for camera mode
        if (this.recordingType === "camera") {
          this.cameraStream = await navigator.mediaDevices.getUserMedia({
            video: { width: 1280, height: 720 },
          });
          this.$nextTick(() => {
            if (this.$refs.cameraPreview) {
              this.$refs.cameraPreview.srcObject = this.cameraStream;
            }
          });
        }

        // Initialize recording (get streams)
        await this.initializeRecording();

        // Start recording
        this.startRecording();
      } catch (error) {
        console.error(
          "Failed to start recording or enter Picture-in-Picture mode:",
          error
        );
        await this.setupMiniPreview();
      }
    },
    async initializeRecording() {
      try {
        // If microphone is enabled, capture audio stream
        if (this.microphoneEnabled) {
          this.audioStream = await navigator.mediaDevices.getUserMedia({
            audio: {
              echoCancellation: true,
              noiseSuppression: true,
              autoGainControl: true,
            },
          });
        }

        // If screen or screenCam is selected, capture screen stream
        if (
          this.recordingType === "screen" ||
          this.recordingType === "screenCam"
        ) {
          const displayMediaOptions = {
            video: {
              width: { ideal: this.getResolutionWidth() },
              height: { ideal: this.getResolutionHeight() },
              frameRate: { ideal: 30 },
            },
            audio: !navigator.userAgent.toLowerCase().includes("firefox"), // Firefox does not support system audio
          };

          this.screenStream = await navigator.mediaDevices.getDisplayMedia(
            displayMediaOptions
          );

          this.$nextTick(() => {
            if (this.$refs.screenPreview) {
              this.$refs.screenPreview.srcObject = this.screenStream;
            }
          });

          // Add enhanced stream stop handling
          const cleanup = this.addStreamStopListener(this.screenStream, () => {
            console.log("Screen sharing stopped");
            // this.stopRecording();
            this.handleBrowserStopRecording();
          });

          // Store cleanup function for later use
          this._streamCleanup = cleanup;
        }

        // If screenCam is selected, capture camera stream
        if (this.recordingType === "screenCam") {
          this.cameraStream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
        }

        // Combine tracks if multiple streams are used
        const tracks = this.getCombinedTracks();
        this.combinedStream = new MediaStream(tracks);

        // Start countdown or recording
        this.startCountdown();
      } catch (error) {
        console.error("Error initializing recording:", error);
        this.toast.staticError(
          "Failed to initialize recording. Please check your permissions and try again."
        );
      }
    },
    handleBrowserStopRecording() {
      if (!this.recorder || !this.isRecording) return;

      console.log("Browser stop button detected...");
      clearInterval(this.recordingTimer);

      this.recorder.stopRecording(async () => {
        try {
          const recordedBlob = this.recorder.getBlob();
          const fixedBlob = await this.fixWebMMetadata(recordedBlob);

          this.recordedVideo = fixedBlob;
          this.recordedVideoUrl = URL.createObjectURL(fixedBlob);

          this.isRecording = false;
          this.isFirstPage = false;
          this.isFinalStep = false;
          this.isPreviewMode = true;

          // Clean up everything
          this.cleanupRecording();
        } catch (error) {
          console.error("Error processing video:", error);
          this.cleanupRecording();
        }
      });
    },
    startRecording() {
      const options = {
        type: "video",
        mimeType: navigator.userAgent.toLowerCase().includes("firefox")
          ? "video/webm;codecs=vp8,opus"
          : "video/webm;codecs=vp8",
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: this.videoQuality,
        frameInterval: 30,
        timeSlice: 5000, // ⬅️ Save data every 5 seconds
        disableLogs: true,
      };

      this.chunks = []; // Store recorded chunks
      this.recorder = new RecordRTC(this.combinedStream, options);

      // Capture data in chunks to avoid memory issues
      this.recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.chunks.push(event.data);
        }
      };

      this.recordingStartTime = Date.now();
      this.startRecordingTimer();
      this.recorder.startRecording();
    },
    stopRecording() {
      if (!this.recorder || !this.isRecording) return;

      console.log("Stopping recording...");
      clearInterval(this.recordingTimer);

      // Stop all tracks first
      if (this.screenStream) {
        this.screenStream.getTracks().forEach((track) => {
          track.stop();
        });
      }

      // Cleanup stream listeners
      if (this._streamCleanup) {
        this._streamCleanup();
        this._streamCleanup = null;
      }

      this.recorder.stopRecording(async () => {
        try {
          const recordedBlob = this.recorder.getBlob();
          const fixedBlob = await this.fixWebMMetadata(recordedBlob);

          this.recordedVideo = fixedBlob;
          this.recordedVideoUrl = URL.createObjectURL(fixedBlob);

          this.isRecording = false;
          this.isFirstPage = false;
          this.isFinalStep = false;
          this.isPreviewMode = true;

          // Clean up everything
          this.cleanupRecording();
        } catch (error) {
          console.error("Error processing video:", error);
          this.cleanupRecording();
        }
      });
    },
    addStreamStopListener(stream, callback) {
      let stopped = false;

      const stopAndCleanup = () => {
        if (stopped) return;
        stopped = true;

        // Stop the recording if it's active
        if (this.isRecording) {
          console.log("Stream stop detected - stopping recording");
          // this.stopRecording();

          // Force cleanup of timer and state
          // clearInterval(this.recordingTimer);
          // this.isRecording = false;
          // this.resetRecordingState();
          this.handleBrowserStopRecording();
        }

        callback?.();
      };

      // Add listeners to both stream and tracks
      stream.addEventListener("ended", stopAndCleanup, { once: true });
      stream.addEventListener("inactive", stopAndCleanup, { once: true });

      stream.getTracks().forEach((track) => {
        track.addEventListener("ended", stopAndCleanup, { once: true });
        track.addEventListener("inactive", stopAndCleanup, { once: true });
      });

      // Return cleanup function
      return () => {
        stream.removeEventListener("ended", stopAndCleanup);
        stream.removeEventListener("inactive", stopAndCleanup);
        stream.getTracks().forEach((track) => {
          track.removeEventListener("ended", stopAndCleanup);
          track.removeEventListener("inactive", stopAndCleanup);
        });
      };
    },
    async fixWebMMetadata(blob) {
      return new Promise((resolve, reject) => {
        if (typeof EBML === "undefined") {
          console.error(
            "EBML.js is missing! Include https://cdn.webrtc-experiment.com/EBML.js"
          );
          reject("EBML.js is not loaded");
          return;
        }

        const reader = new EBML.Reader();
        const decoder = new EBML.Decoder();
        const tools = EBML.tools;
        const fileReader = new FileReader();

        fileReader.onload = function () {
          try {
            // Process the EBML data carefully
            const ebmlElms = decoder.decode(this.result);

            // Initialize metadata processing
            try {
              ebmlElms.forEach((element) => reader.read(element));
              reader.stop();

              // Check if we have valid metadata
              if (reader.metadatas && reader.duration && reader.cues) {
                const refinedMetadataBuf = tools.makeMetadataSeekable(
                  reader.metadatas,
                  reader.duration,
                  reader.cues
                );
                const body = this.result.slice(reader.metadataSize);
                const newBlob = new Blob([refinedMetadataBuf, body], {
                  type: "video/webm",
                });
                resolve(newBlob);
              } else {
                // Missing required metadata, return original
                console.warn("Incomplete WebM metadata, using original blob");
                resolve(blob);
              }
            } catch (metadataError) {
              // If we get an error about unknown tags, return the original blob
              if (
                metadataError.message &&
                metadataError.message.includes("No schema entry found for")
              ) {
                console.warn(
                  "WebM structure not compatible with EBML.js, using original blob"
                );
                resolve(blob);
              } else {
                // For other metadata errors, reject as before
                console.error("Error in metadata processing:", metadataError);
                reject(metadataError);
              }
            }
          } catch (decodeError) {
            console.error("Error decoding EBML data:", decodeError);
            reject(decodeError);
          }
        };

        fileReader.onerror = function (error) {
          console.error("FileReader error:", error);
          reject(error);
        };

        fileReader.readAsArrayBuffer(blob);
      });
    },

    async attemptEnterPiP() {
      // Check if PiP is supported
      if (!document.pictureInPictureEnabled) {
        console.warn("Picture-in-Picture is not supported in this browser.");
        await this.setupMiniPreview();
        return;
      }

      // Request camera access for the mirror preview
      try {
        const cameraStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        const pipVideo = document.createElement("video");
        pipVideo.srcObject = cameraStream;
        pipVideo.muted = true;
        pipVideo.style.display = "none";
        document.body.appendChild(pipVideo);

        // Ensure the video starts playing before requesting PiP
        await pipVideo.play();

        // Attempt to enter PiP mode
        await pipVideo.requestPictureInPicture();
        this.isPipActive = true;

        // Clean up PiP video when it exits
        pipVideo.addEventListener("leavepictureinpicture", () => {
          this.isPipActive = false;
          document.body.removeChild(pipVideo);
          cameraStream.getTracks().forEach((track) => track.stop()); // Stop the camera stream
        });
      } catch (error) {
        console.error("Failed to enter Picture-in-Picture mode:", error);
        await this.setupMiniPreview();
      }
    },

    getCombinedTracks() {
      const tracks = [];

      if (this.recordingType === "camera") {
        tracks.push(...this.cameraStream.getVideoTracks());
      } else if (this.recordingType === "screen") {
        tracks.push(...this.screenStream.getVideoTracks());
      } else if (this.recordingType === "screenCam") {
        tracks.push(...this.screenStream.getVideoTracks());
      }

      if (this.microphoneEnabled && this.audioStream) {
        tracks.push(...this.audioStream.getAudioTracks());
      }

      const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
      if (
        (this.recordingType === "screen" ||
          this.recordingType === "screenCam") &&
        !isFirefox &&
        this.screenStream.getAudioTracks().length > 0
      ) {
        tracks.push(...this.screenStream.getAudioTracks());
      }

      return tracks;
    },

    startCountdown() {
      this.isCountingDown = true;
      this.countdownValue = 3;
      const countdownInterval = setInterval(() => {
        this.countdownValue--;
        if (this.countdownValue === 0) {
          clearInterval(countdownInterval);
          this.isCountingDown = false;
          this.startRecording();
        }
      }, 1000);
    },

    async setupMiniPreview() {
      // this.cleanupMiniPreview();

      this.miniPreview = document.createElement("div");
      this.miniPreview.style.cssText = `
        position: fixed;
        right: 20px;
        bottom: 20px;
        width: 220px;
        height: 180px;
        background: black;
        border: 2px solid #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.2);
        z-index: 9999;
        overflow: hidden;
        cursor: move;
        transition: transform 0.2s ease;
      `;

      try {
        this.miniCameraStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        this.miniPreviewVideo = document.createElement("video");
        this.miniPreviewVideo.style.cssText = `
          width: 100%;
          height: 100%;
          object-fit: cover;
        `;
        this.miniPreviewVideo.autoplay = true;
        this.miniPreviewVideo.muted = true;
        this.miniPreviewVideo.playsInline = true;
        this.miniPreviewVideo.srcObject = this.miniCameraStream;

        this.setupDragHandlers();

        this.miniPreview.appendChild(this.miniPreviewVideo);
        document.body.appendChild(this.miniPreview);
      } catch (error) {
        console.error("Error accessing camera:", error);
        this.cleanupMiniPreview();
      }
    },

    setupDragHandlers() {
      this.miniPreview.addEventListener("mousedown", this.handleDragStart);
      this.miniPreview.addEventListener("touchstart", this.handleTouchStart);
      document.addEventListener("mousemove", this.handleDrag);
      document.addEventListener("touchmove", this.handleTouchMove);
      document.addEventListener("mouseup", this.handleDragEnd);
      document.addEventListener("touchend", this.handleDragEnd);
    },

    handleDragStart(e) {
      if (!this.miniPreview) return;

      this.isDragging = true;
      this.dragOffset = {
        x:
          (e.touches ? e.touches[0].clientX : e.clientX) -
          this.miniPreview.offsetLeft,
        y:
          (e.touches ? e.touches[0].clientY : e.clientY) -
          this.miniPreview.offsetTop,
      };

      this.miniPreview.style.transform = "scale(1.05)";
    },

    handleTouchStart(e) {
      e.preventDefault();
      this.handleDragStart(e);
    },

    handleDrag(e) {
      if (!this.isDragging || !this.miniPreview) return;

      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;

      const x = clientX - this.dragOffset.x;
      const y = clientY - this.dragOffset.y;

      const maxX = window.innerWidth - this.miniPreview.offsetWidth;
      const maxY = window.innerHeight - this.miniPreview.offsetHeight;

      this.miniPreview.style.left = `${Math.max(0, Math.min(x, maxX))}px`;
      this.miniPreview.style.top = `${Math.max(0, Math.min(y, maxY))}px`;
    },

    handleTouchMove(e) {
      e.preventDefault();
      this.handleDrag(e);
    },

    handleDragEnd() {
      this.isDragging = false;
      if (this.miniPreview) {
        this.miniPreview.style.transform = "scale(1)";
      }
    },

    startRecordingTimer() {
      this.recordingTimer = setInterval(() => {
        if (!this.isPaused) {
          const currentTime = Date.now();
          this.totalRecordingTime =
            currentTime - this.recordingStartTime - this.pausedTime;
        }
      }, 1000);
    },

    togglePause() {
      if (this.isPaused) {
        this.recorder.resumeRecording();
        this.pausedTime += Date.now() - this.lastPauseTime;
        this.lastPauseTime = null;
      } else {
        this.recorder.pauseRecording();
        this.lastPauseTime = Date.now();
      }
      this.isPaused = !this.isPaused;
    },

    cleanupRecording() {
      this.stopAllStreams();
      this.cleanupPreviewElements();
      this.cleanupMiniPreview();
      this.resetRecordingState();
    },

    stopAllStreams() {
      [
        this.cameraStream,
        this.screenStream,
        this.audioStream,
        this.combinedStream,
      ].forEach((stream) => {
        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
            track.enabled = false;
          });
        }
      });

      // Clear stream references
      this.cameraStream = null;
      this.screenStream = null;
      this.audioStream = null;
      this.combinedStream = null;
    },

    cleanupPreviewElements() {
      // Cleanup PiP
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture().catch(console.error);
      }
      if (this.pipVideo) {
        // Clean up will now happen inside `leavepictureinpicture` event
        this.pipVideo = null;
      }
    },
    cleanupMiniPreview() {
      if (this.miniPreview) {
        this.miniPreview.remove();
        this.miniPreview = null;
      }
      this.miniPreviewVideo.srcObject
        .getTracks()
        .forEach((track) => track.stop());
      this.miniPreviewVideo.srcObject = null;
      this.miniCameraStream.getTracks().forEach((track) => track.stop());
      this.miniCameraStream = null;
      console.log("Cleaned up mini preview...");
      if (this.$refs.miniPreview && this.$refs.miniPreview.srcObject) {
        this.$refs.miniPreview.srcObject
          .getTracks()
          .forEach((track) => track.stop());
        this.$refs.miniPreview.srcObject = null;
      }
    },

    removeDragHandlers() {
      if (!this.miniPreview) return;

      this.miniPreview.removeEventListener("mousedown", this.handleDragStart);
      this.miniPreview.removeEventListener("touchstart", this.handleTouchStart);
      document.removeEventListener("mousemove", this.handleDrag);
      document.removeEventListener("touchmove", this.handleTouchMove);
      document.removeEventListener("mouseup", this.handleDragEnd);
      document.removeEventListener("touchend", this.handleDragEnd);
    },

    resetRecordingState() {
      this.recordingStartTime = null;
      this.totalRecordingTime = 0;
      this.pausedTime = 0;
      this.lastPauseTime = null;
      this.isPaused = false;
    },

    getResolutionWidth() {
      const resolutions = {
        1000000: 640, // 360p
        2500000: 854, // 480p
        5000000: 1280, // 720p
        8000000: 1920, // 1080p
        16000000: 2560, // 2K
        40000000: 3840, // 4K
      };
      return resolutions[this.videoQuality] || 1280;
    },

    getResolutionHeight() {
      const resolutions = {
        1000000: 360, // 360p
        2500000: 480, // 480p
        5000000: 720, // 720p
        8000000: 1080, // 1080p
        16000000: 1440, // 2K
        40000000: 2160, // 4K
      };
      return resolutions[this.videoQuality] || 1080;
    },

    redoRecording() {
      this.resetUploadSession();
      this.isPreviewMode = false;
      this.isFinalStep = false;
      this.isCompleted = false;
      this.isFirstPage = true;

      if (this.recordedVideoUrl) {
        URL.revokeObjectURL(this.recordedVideoUrl);
      }
      this.recordedVideo = null;
      this.recordedVideoUrl = null;
      if (this.$refs.previewVideo) {
        this.$refs.previewVideo.src = "";
      }

      window.location.reload();
    },

    async generateUploadToken() {
      try {
        const response = await this.$axios.get(
          "/v2/recordings/get-upload-token"
        );
        this.uploadToken = response.token;
        console.log("Generated upload token...");
      } catch (error) {
        this.toast.staticError(
          "Failed to get upload token. Check your connection."
        );
        throw error; // Prevent upload from starting
      }
    },

    proceedToFinal() {
      this.isPreviewMode = false;
      this.isFinalStep = true;
    },

    goBack() {
      this.isPreviewMode = true;
      this.isFinalStep = false;
    },

    cancelUpload() {
      if (this.resumable) {
        this.resumable.cancel();
        this.isUploading = false;
        this.goBack();
        this.toast.staticError("Upload has been canceled.");
      }
    },

    async uploadVideo(childForm) {
      // Merge all the data form
      this.form = { ...this.form, ...childForm };

      // Reset any existing session
      this.resetUploadSession();

      if (this.isUploading) return;

      this.isFinalStep = false;
      this.isUploading = true;
      try {
        // Generate token if missing
        if (!this.uploadToken) {
          await this.generateUploadToken();
        }

        this.form.duration = Math.floor(this.totalRecordingTime / 1000);
        if (this.form.duration < 60) {
          this.form.duration = 60;
        }

        this.video_name = `video_${Date.now()}_${Math.floor(
          Math.random() * 1000
        )}.webm`;

        this.form.video = new File([this.recorder.getBlob()], this.video_name, {
          type: "video/webm",
        });

        const domain = () => {
          if (window.location.href.indexOf(process.env.VUE_APP_URL) === -1) {
            return { domain: window.location.host };
          }
          return null;
        };

        const support = () => {
          if (this.$route.query.support) {
            return { support: this.$route.query.support };
          }
          return null;
        };

        if (this.isRequestLink) {
          this.form.record_link = this.$route.params.personal_url;
          this.form.requested_link = "Yes";
        }

        const get_request_link = () =>
          this.isRequestLink
            ? {
                record_link: this.$route.params.personal_url,
                requested_link: "Yes",
              }
            : null;

        const baseURL =
          process.env.NODE_ENV === "production"
            ? process.env.VUE_APP_PRODUCTION_URL
            : process.env.VUE_APP_STAGING_URL;

        const response = await this.user_can_upload(this.form);
        if (response.data.status === "allowed") {
          this.resumable = new Resumable({
            target: baseURL + "/v2/recordings/upload-video",
            chunkSize: 1 * 1024 * 1024,
            simultaneousUploads: 3,
            testChunks: true,
            query: {
              _token: Math.floor(Math.random() * 1000),
              upload_token: this.uploadToken,
              name: this.form.name,
              email: this.form.email,
              title: this.form.record_title,
              folder_id: this.form.record_folder,
              message: this.form.record_message,
              platform: window.navigator.userAgent,
              size: this.recorder.getBlob().size,
              user_id: this.form.user_id,
              duration: this.form.duration,
              quality: this.getVideoQualityValue,
              ...domain(),
              ...support(),
              ...get_request_link(),
            },
          });

          // Add the file to upload
          this.resumable.addFile(this.form.video);

          // Set up event handlers
          this.resumable.on("fileAdded", () => {
            this.resumable.upload();
          });

          this.resumable.on("fileProgress", (file) => {
            const progress = Math.floor(file.progress() * 100);
            this.progressTracker = progress;
          });

          this.resumable.on("fileSuccess", () => {
            this.handleSuccess("Video uploaded successfully!");
            this.isCompleted = true;
            this.isUploading = false;
          });

          this.resumable.on("fileError", (file, message) => {
            this.progressTracker = 0;
            const errorResponse = JSON.parse(message);
            const errorMessage =
              errorResponse.message || "Upload failed. Please try again.";

            this.isFinalStep = true;
            this.isUploading = false;
            this.toast.staticError(errorMessage);
          });
        } else {
          this.toast.staticError(response.message);
        }
      } catch (e) {
        this.isFinalStep = true;
        this.isUploading = false;
        this.toast.sendError(e);
      }
    },

    resetUploadSession() {
      // Cancel any ongoing upload
      if (this.resumable) {
        this.resumable.cancel();
      }

      // Reset token and state
      this.uploadToken = null;
      this.isUploading = false;

      // Optionally clear any file references
      this.recordedVideo = null;
    },

    handleSuccess(message) {
      this.toast.sendSuccess({
        message: message,
      });
    },

    handleBeforeUnload(event) {
      if (this.isRecording) {
        event.preventDefault();
        event.returnValue =
          "You have an active recording. Are you sure you want to leave?";
        return event.returnValue;
      }
    },
    async fetchAuthUserBranding() {
      this.loadScreen = true;
      try {
        const response = await this.get_user_branding(this.form.user_id);

        if (response?.data?.is_default) {
          this.appDomainLogoUrl = require("../../assets/logo.png");
          localStorage.removeItem("favicon");
          localStorage.removeItem("tagline");
          localStorage.removeItem("domain_logo");
          localStorage.setItem("is_custom_domain", false);

          console.log("Default Domain");
        } else {
          const defaultDomain = response.data;
          this.appDomainLogoUrl = defaultDomain.domain_logo_url;

          localStorage.setItem("favicon", defaultDomain.favicon_url);
          localStorage.setItem("tagline", defaultDomain.tagline);
          localStorage.setItem("domain_logo", defaultDomain.domain_logo_url);
          let checkDefault = defaultDomain.tagline !== undefined ? true : false;
          localStorage.setItem("is_custom_domain", checkDefault);

          this.brandColor = defaultDomain.branding.section_bg_color;
          this.buttonColor = defaultDomain.branding.accent_color;
          this.buttonTextColor = defaultDomain.branding.button_text_color;

          console.log("Custom Domain");
        }
      } catch (e) {
        this.toast.sendError(e);
      } finally {
        this.loadScreen = false;
      }
    },
    async fetchRequestUserBranding() {
      this.loadScreen = true;

      try {
        const protocol =
          process.env.NODE_ENV === "production" ? "https://" : "http://";

        const personalUrl = this.$route.params.personal_url;
        const link = `${protocol}${window.location.host}/request/${personalUrl}`;
        this.form.link = link;

        const response = await this.get_req_user_branding(this.form);

        // if (response?.data?.is_default) {}
        var defaultDomain = null;

        // check CATEGORY matches
        if (response.data.record_link) {
          let domainsDtls = response?.data?.user?.custom_domains;

          defaultDomain = domainsDtls.find(
            (domain) => domain.default === "yes"
          );
        }

        // check DOMAIN matches
        else if (response?.data?.domain_logo_url) {
          defaultDomain = response.data;
        }

        // neither CATEGORY nor DOMAIN matches
        else {
          console.warn("neither CATEGORY nor DOMAIN matches");
        }

        // collect receiver details
        this.receiver_name_email =
          response.data.user.name ?? response.data.user.email;

        if (defaultDomain !== null && defaultDomain !== undefined) {
          this.appDomainLogoUrl = defaultDomain.domain_logo_url;

          localStorage.setItem("favicon", defaultDomain.favicon_url);
          localStorage.setItem("tagline", defaultDomain.tagline);
          localStorage.setItem("domain_logo", defaultDomain.domain_logo_url);
          let checkDefault = defaultDomain.tagline !== undefined ? true : false;
          localStorage.setItem("is_custom_domain", checkDefault);

          this.brandColor = defaultDomain.branding.section_bg_color;
          this.buttonColor = defaultDomain.branding.accent_color;
          this.buttonTextColor = defaultDomain.branding.button_text_color;
          console.log("Request Custom Domain");
        } else {
          this.appDomainLogoUrl = require("../../assets/logo.png");
          localStorage.removeItem("favicon");
          localStorage.removeItem("tagline");
          localStorage.removeItem("domain_logo");
          localStorage.setItem("is_custom_domain", false);
          console.log("Request Default Domain");
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.loadScreen = false;
      }
    },
  },
  beforeDestroy() {
    if (this._streamCleanup) {
      this._streamCleanup();
    }
    this.resetUploadSession();
    this.cleanupRecording();
    clearInterval(this.recordingTimer);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
};
</script>

<style lang="scss" scoped>
.recording-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.custom-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.recording-title {
  font-size: 24px !important;
  color: #333;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-bottom: 1.5rem;
}

.content-preview {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  background-color: #000;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.8);

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;

    .overlay-icon {
      width: 70px;
      height: 70px;
      margin-bottom: 10px;
    }

    .overlay-text {
      h2 {
        font-size: 22px;
        margin: 2px;
      }

      p {
        font-size: 13px;
        margin: 0;
      }
    }
  }
}

.recording-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.recording-timer {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-top: 0.5rem;
}

.countdown-card {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  .display-4 {
    color: white;
    font-weight: bold;
  }
}

.preview {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  video {
    background-color: #000;
  }
}

.logo-size {
  height: 50px;
  width: 150px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .custom-card {
    margin: 0.5rem;
  }

  .content-preview {
    width: 95%;
  }

  .recording-controls {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
