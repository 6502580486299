<template>
  <div>
    <div class="d-flex justify-center">
      <strong>Uploading... {{ progressTracker }}%</strong>
    </div>
    <div class="mt-5">
      <v-progress-linear
        height="15"
        color="primary"
        class="rounded-xl"
        v-model="progressTracker"
      ></v-progress-linear>
    </div>
    <span class="mt-4 d-block">
      <v-btn @click="cancel" class="capitalize" rounded color="error">
        <v-icon title="Cancel" class="mr-2">mdi-close-circle-outline</v-icon>
        Cancel
      </v-btn>
    </span>
  </div>
</template>

<script>
export default {
  name: "UploadProgress",
  props: {
    progressTracker: {
      default: 0,
      type: Number,
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel-upload");
    },
  },
};
</script>

<style scoped></style>
